import React from 'react'
import CTAButton from '../components/generic/CTAButton'

const HHAllePage = (options: any) => {
  return (
    <>
      <h2>Alle Handyhüllen-Motive</h2>
      <p>
        Unsere Handyhüllen sind verfügbar als Premium-Case und Tough-Case für
        Apple- und Samsung Smartphones. Suche dir zuerst ein Motiv aus und
        entscheide dich anschließend für die Art der Hülle und wähle dein
        Handymodell aus:
      </p>
      <CTAButton
        context="hh_lp_manufacturer"
        link="/geschenke/handyhuellen/alle/bestseller"
        title="Alle Handyhüllen-Motive entdecken"
      />
    </>
  )
}

export default HHAllePage
